/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useRouter } from "next/router";
import Script from "next/script";
import classNames from "classnames";
import useSWR, { useSWRConfig } from "swr";
import Modal from "react-modal";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import { useTranslations } from "next-intl";
import Cookies from "js-cookie";
import { StoryContext } from "../pages/[...route]";
import { centraGetProduct, centraPut } from "../lib/centra-api";
import { generateStoryTree, StoryWithChildren } from "../lib/storyblok-api";
import { useMediaQuery } from "../lib/utils";
import CentraProductLink from "./CentraProductLink";
import CentraProductImage from "./CentraProductImage";
import MobileMenu from "./MobileMenu";
import Link, { LinkProps } from "./Link";
import StoryblokImage from "./StoryblokImage";

declare global {
  interface Window {
    fcWidget: any;
  }
}

async function arrayFetcher(
  ids: string[],
  token: string,
  locale: string,
  currency: string
): Promise<CentraProductApiData[]> {
  return await Promise.all(
    ids.map(async (id) =>
      centraGetProduct(`centraproduct://${id}/${locale}/${currency}`, token)
    )
  );
}

const getFirstLetter = (c) => {
  const firstLetter = c.content.name?.slice(0, 1);
  return /\p{Letter}/u.test(firstLetter) && firstLetter ? firstLetter : "#";
};

export default function Header(): React.ReactElement {
  const router = useRouter();
  const isGiftPage =
    router.route === "/gift" || router.route === "/gift/receipt";
  const isCheckoutPage = router.route === "/checkout";

  const context = useContext(StoryContext);
  const { currency, token, selectionData } = context;
  // const [token, setToken] = useCentraToken();
  const menuRef = useRef<HTMLDivElement>();
  const searchMenuRef = useRef<HTMLDivElement>();
  const isTouch = useMediaQuery("not (hover)");
  const t = useTranslations("Header");
  const { mutate } = useSWRConfig();

  /* Old search states before Depict was implemented
   */
  // const [searchTerm, setSearchTerm] = useState("");
  // const [productSearchResults, setProductSearchResults] = useState([]);
  // const [categorySearchResults, setCategorySearchResults] = useState([]);
  // const [searchResultsTotal, setSearchResultsTotal] = useState(0);
  // const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  // const [searchController, setSearchController] = useState<AbortController>();
  // const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const [searchIsActive, setSearchIsActive] = useState(false);
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const [menuTopContactIsVisible, setActiveTopContact] = useState(false);
  const [activeCat, setActiveCat] = useState<StoryWithChildren>();

  function toggleActiveCat(cat) {
    setActiveCat(activeCat?.id === cat.id ? null : cat);
  }

  // Support Centra's "order creation" functionality.
  useEffect(() => {
    if (token && router.query.selection) {
      const newUrl = new URL(location.href);
      const newSearch = new URLSearchParams(location.search);
      newSearch.delete("selection");
      newUrl.search = newSearch.toString();

      router.replace(newUrl.href, null, { shallow: true });

      centraPut(
        `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/selection/${router.query.selection}`,
        token
      ).then((data) => {
        mutate(selectionUrl, data, false);
      });
    }
  }, [token, router.query.selection]);

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [breadcrumbsTop, setBreadcrumbsTop] = useState(0);
  const headerRef = useRef<HTMLDivElement>(null);
  const navWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      const header = headerRef.current;
      const navWrapper = navWrapperRef.current;
      const breadcrumbs = document.querySelector(".Breadcrumbs") as HTMLElement;

      if (header && navWrapper) {
        const navWrapperHeight = navWrapper.offsetHeight;
        const breadcrumbsHeight = breadcrumbs ? breadcrumbs.offsetHeight : 0;

        if (currentScrollTop > lastScrollTop) {
          // Scrolling down
          setHeaderTop(
            Math.max(
              headerTop - (currentScrollTop - lastScrollTop),
              -navWrapperHeight
            )
          );
          setBreadcrumbsTop(
            Math.max(
              breadcrumbsTop - (currentScrollTop - lastScrollTop),
              -(navWrapperHeight + breadcrumbsHeight)
            )
          );
        } else {
          // Scrolling up
          setHeaderTop(
            Math.min(headerTop + (lastScrollTop - currentScrollTop), 0)
          );
          setBreadcrumbsTop(
            Math.min(breadcrumbsTop + (lastScrollTop - currentScrollTop), 0)
          );
        }

        header.style.top = `${headerTop}px`;

        // Adjust the .Breadcrumbs element
        if (breadcrumbs) {
          breadcrumbs.style.transform = `translateY(${breadcrumbsTop}px)`;
        }
      }

      setLastScrollTop(currentScrollTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop, headerTop, breadcrumbsTop]);

  // useEffect(() => {
  //   if (searchIsActive) {
  //     setActiveCat(null);
  //   } else {
  //     setSearchTerm("");
  //     setCategorySearchResults([]);
  //     setProductSearchResults([]);
  //     setSearchResultsTotal(0);
  //     setLoadingSearchResults(false);
  //     searchController?.abort();
  //   }
  // }, [searchIsActive]);

  useEffect(() => {
    if (activeCat) {
      if (menuIsVisible) {
        setSearchIsActive(false);
      }
    } else {
      setMenuIsVisible(false);
      clearTimeout(timeoutIdRef.current);
    }
  }, [activeCat, menuIsVisible]);

  const activeCatRef = useRef<StoryWithChildren>();
  const timeoutIdRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!activeCatRef.current) {
      timeoutIdRef.current = setTimeout(() => {
        setMenuIsVisible(true);
      }, 400);
    }

    activeCatRef.current = activeCat;
  }, [activeCat]);

  // async function setCustomerCurrency(country: string) {
  //   const res = await fetch("/api/change-currency", {
  //     method: "POST",
  //     headers: { "content-type": "application/json; charset=UTF-8" },
  //     body: JSON.stringify({ country, token }),
  //     redirect: "follow",
  //   });

  //   if (res.ok) {
  //     setCurrency(country);
  //   }
  // }

  function resolveLink(block) {
    if (block.link.linktype === "story") {
      const resolvedLink = context.mainMenu.links?.find(
        (ll) => ll.uuid === block.link.id
      );

      if (resolvedLink) {
        const linkTree = generateStoryTree(
          context.menuItems,
          resolvedLink.full_slug,
          0
        );

        block.link.story = linkTree[0]?.children
          ? { ...resolvedLink, children: linkTree[0].children }
          : resolvedLink;
      } else {
        block.link.story = { name: "Save to view" };
      }
    }

    return block;
  }

  const headerToRight = useMemo(
    () =>
      context.mainMenu?.content?.header_top_right
        ?.map(resolveLink)
        .filter((b) => b.text || b.link.story?.name),
    [context.mainMenu?.content?.header_top_right]
  );

  const headerLinksLeft = useMemo(
    () =>
      context.mainMenu?.content?.header_links_left
        ?.map(resolveLink)
        .filter((b) => b.text || b.link.story?.name),
    [context.mainMenu?.content?.header_links_left]
  );

  const headerLinksRight = useMemo(
    () =>
      context.mainMenu?.content?.header_links_right
        ?.map(resolveLink)
        .filter((b) => b.text || b.link.story?.name),
    [context.mainMenu?.content?.header_links_right]
  );

  const headerCustomMenuLinks = useMemo(
    () =>
      context.mainMenu?.content?.custom_mega_menu_links
        ?.map((item) => {
          return resolveLink(item.link[0]);
        })
        .filter((b) => b.text || b.link.story?.name),
    [context.mainMenu?.content?.custom_mega_menu_links]
  );

  const headerCustomMenuItems =
    context.mainMenu?.content?.custom_mega_menu_links;

  const selectionUrl = `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/selection`;

  const featuredProductIds: string[] =
    activeCat?.content.featured_product?.productIds;
  const { data: featuredProductsData } = useSWR(
    featuredProductIds?.length ? JSON.stringify(featuredProductIds) : null,
    (ids) => arrayFetcher(JSON.parse(ids), token, router.locale, currency)
  );
  const featuredLinks = activeCat?.content.featured_links;

  const setSelectionLanguage = async (lang) => {
    return await centraPut(
      `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/languages/${lang}`,
      token
    );
  };

  useEffect(() => {
    featuredProductsData?.forEach((p) => {
      mutate(
        `centraproduct://${p.product.product}/${router.locale}/${currency}`,
        p,
        false
      );
    });
  }, [featuredProductsData]);

  const bagItemsCount =
    selectionData?.selection.items.reduce(
      (count, item) => count + item.quantity,
      0
    ) || 0;

  useEffect(() => {
    if (window.fcWidget) {
      if (selectionData?.loggedIn?.firstName) {
        window.fcWidget.user.setFirstName(selectionData.loggedIn.firstName);
      } else if (selectionData?.selection.shippingAddress.firstName) {
        window.fcWidget.user.setFirstName(
          selectionData.selection.shippingAddress.firstName
        );
      }

      if (selectionData?.loggedIn?.email) {
        window.fcWidget.user.setEmail(selectionData.loggedIn.email);
      } else if (selectionData?.selection.shippingAddress.email) {
        window.fcWidget.user.setEmail(
          selectionData.selection.shippingAddress.email
        );
      }
    }
  }, [router.asPath]);

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    setMenuIsOpen(false);
    setActiveCat(null);
  }, [router.asPath, router.locale]);

  /***** Old search functionality
   */
  // const searchProducts = (term, page = 1) => {
  //   if (searchController) {
  //     searchController.abort();
  //   }

  //   const query = new URLSearchParams();
  //   query.set("term", term);
  //   query.set("locale", router.locale);
  //   query.set("page", `${page}`);
  //   const url = new URL("/api/product-search", location.href);
  //   url.search = query.toString();

  //   const controller = new AbortController();
  //   setSearchController(controller);
  //   setLoadingSearchResults(true);

  //   fetch(url.href, {
  //     signal: controller.signal,
  //   })
  //     .then((res) => {
  //       if (res.ok) {
  //         return res.json();
  //       } else {
  //         throw new Error(`${res.status}`);
  //       }
  //     })
  //     .then((data) => {
  //       data.results.forEach((p) => {
  //         const key = `centraproduct://${p.product}/${router.locale}`;
  //         mutate(key, { product: p });
  //       }, {});

  //       setCategorySearchResults(data.categories);
  //       setProductSearchResults(
  //         page > 1 ? productSearchResults.concat(data.results) : data.results
  //       );
  //       setSearchResultsTotal(data.totalResults);
  //       setLoadingSearchResults(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // const loadMoreSearchResults = () => {
  //   const page = productSearchResults.length / 100 + 1;
  //   searchProducts(searchTerm, page);
  // };

  // const submitSearch = (event) => {
  //   event.preventDefault();
  //   event.stopPropagation();

  //   searchProducts(searchTerm);
  // };

  // useEffect(() => {
  //   if (!debouncedSearchTerm) {
  //     return;
  //   }

  //   searchProducts(debouncedSearchTerm);
  // }, [debouncedSearchTerm]);

  useEffect(() => {
    function mouseLeave() {
      setActiveCat(null);
    }

    document.documentElement.addEventListener("mouseleave", mouseLeave);

    return () => {
      document.documentElement.removeEventListener("mouseleave", mouseLeave);
    };
  }, []);

  const [dropdownOpen, setDropdownOpen] = useState(false); // State to manage dropdown visibility

  const handleCurrencySelect = (selectedCurrency: string) => {
    setDropdownOpen(false);
    context.setCustomerCurrency(selectedCurrency);
  };

  const header = (
    <header className="Header" ref={headerRef}>
      <a className="Header-skipToContentLink" href="#main">
        {t("skipToContent")}
      </a>

      <div className="Header-top">
        <div className="Header-navWrapper" ref={navWrapperRef}>
          <div className="Header-nav">
            <li className="Header-navItem">
              <button
                onMouseEnter={() => {
                  setActiveTopContact(true);
                }}
              >
                <span>{t("contactUs")}</span>
              </button>
            </li>
            <Modal
              bodyOpenClassName="is-showingMenu"
              className="Header-topMenuModal"
              isOpen={menuTopContactIsVisible}
              onRequestClose={() => setActiveTopContact(null)}
              shouldCloseOnOverlayClick={false}
              // overlayElement={(props, element) =>
              //   React.cloneElement(element, {
              //     ...props,
              //     ref: menuRef,
              //     onMouseOver(event) {
              //       if (event.target === menuRef.current) {
              //         setActiveTopContact(null);
              //       }
              //     },
              //   })
              // }
              style={{
                overlay: {
                  backgroundColor: "var(--color-black)",
                  bottom: "auto",
                  overflow: "auto",
                  right: "auto",
                  top: "var(--top-header-height)",
                  zIndex: 105,
                },
              }}
            >
              <div
                className="Header-topMenuContent"
                onMouseLeave={() => {
                  setActiveTopContact(null);
                }}
              >
                <p>{t("topMenu.contact")}</p>
                <div className="Header-topMenuButtons">
                  <button
                    onClick={() => {
                      window.open("tel:0046317117621");
                    }}
                  >
                    <i className="Icon Icon--phone" />
                    <span>+46 31-711 76 21</span>
                  </button>
                  <button
                    onClick={() => {
                      window.open("mailto:info@artilleriet.se");
                    }}
                  >
                    <i className="Icon Icon--mail" />
                    <span>info@artilleriet.se</span>
                  </button>
                  <button
                    onClick={() => {
                      window.location.href = "/";
                    }}
                  >
                    <span>Till kontaktformuläret</span>
                  </button>
                </div>
              </div>
            </Modal>
            <li className="Header-navItem">
              <button
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                <span>{t("visitUs")}</span>
              </button>
            </li>
          </div>

          <div className="Header-nav">
            {headerToRight?.map((b) => (
              <li
                className={classNames("Header-navItem", {
                  "is-red": b.is_red,
                })}
                key={b._uid}
              >
                <Link
                  href={
                    b.link.story?.full_slug
                      ? `/${b.link.story.full_slug}`
                      : b.link.url
                  }
                  onMouseEnter={() => {
                    setActiveCat(null);
                  }}
                >
                  <span>{b.text || b.link.story?.name}</span>
                </Link>
              </li>
            ))}
          </div>
        </div>
        <div className="Header-navWrapper Header-navWrapper--right"></div>
      </div>

      <div className="Header-content">
        <div className="Header-navWrapper">
          <button
            className={classNames("Header-menuToggle", {
              "is-showingMenu": menuIsOpen,
            })}
          >
            <i
              className={classNames("Icon", {
                "Icon--clear": menuIsOpen,
                "Icon--menu": !menuIsOpen,
              })}
              onClick={() => {
                setMenuIsOpen(!menuIsOpen);
              }}
            />

            {menuIsOpen ? (
              <span>{t("menuClose")}</span>
            ) : (
              <i className="Icon Icon--search depict_navbar_search" />
            )}
          </button>

          <Link
            className="Header-accountButton Header-accountButton--mobile"
            href="/account"
            aria-label={t("account")}
          >
            <i
              className={classNames("Icon", {
                "Icon--user": !selectionData?.loggedIn,
                "Icon--userCheck": selectionData?.loggedIn,
              })}
            />
          </Link>

          <ul className="Header-nav Header-nav--left">
            {headerLinksLeft.map((b) => {
              const props: LinkProps = {
                href: b.link.story?.full_slug
                  ? `/${b.link.story.full_slug}`
                  : b.link.url,
              };

              if (b.link.story?.children) {
                props.onMouseEnter = () => {
                  setActiveCat(b.link.story);
                };
                props.onMouseLeave = () => {
                  if (!menuIsVisible) {
                    setActiveCat(null);
                  }
                };
                props.onClick = (event) => {
                  if (isTouch) {
                    event.preventDefault();
                    toggleActiveCat(b.link.story);
                  }
                };
              } else {
                props.onMouseEnter = () => {
                  setActiveCat(null);
                };
              }

              return (
                <li
                  className={classNames("Header-navItem", {
                    "is-red": b.is_red,
                    "is-active":
                      b.link.story?.children &&
                      b.link.story?.id === activeCat?.id,
                  })}
                  key={b._uid}
                >
                  <Link {...props}>
                    <span>{b.text || b.link.story?.name}</span>
                  </Link>
                </li>
              );
            })}
          </ul>

          <div
            className="Header-searchToggle depict_navbar_search"
            aria-label={t("search.inputLabel")}
          >
            <i
              className={classNames("Icon", {
                "Icon--search": !searchIsActive,
                "Icon--clear": searchIsActive,
              })}
            />
          </div>

          <div className="Header-searchCloser" />
        </div>

        <div className="Header-center">
          <h3 className="Header-wordmarkWrapper">
            <Link href="/">
              <img
                className="Header-wordmark"
                src="/images/artilleriet-wordmark.svg"
                alt="Artilleriet"
              />
            </Link>
          </h3>
        </div>

        <div className="Header-navWrapper Header-navWrapper--right">
          <ul className="Header-nav Header-nav--right">
            {/* New custom mega menu links */}
            {headerCustomMenuLinks &&
              headerCustomMenuLinks?.map((b) => {
                if (!b.link.story) {
                  console.error("no link found");
                  console.log(b);
                  return;
                }

                const props: LinkProps = {
                  href: b.link.story?.full_slug
                    ? `/${b.link.story.full_slug}`
                    : b.link.url,
                };

                props.onMouseEnter = () => {
                  setActiveCat(b.link.story);
                };
                props.onMouseLeave = () => {
                  if (!menuIsVisible) {
                    setActiveCat(null);
                  }
                };
                props.onClick = (event) => {
                  if (isTouch) {
                    event.preventDefault();
                    toggleActiveCat(b.link.story);
                  }
                };

                return (
                  <li
                    className={classNames("Header-navItem", {
                      "is-red": b.is_red,
                      "is-active":
                        b.link.story?.children &&
                        b.link.story?.id === activeCat?.id,
                    })}
                    key={b._uid}
                  >
                    {
                      <Link {...props}>
                        <span>{b.text || b.link.story?.name}</span>
                      </Link>
                    }
                  </li>
                );
              })}

            {/* Static links, used before custom mega menu links was implemented */}
            {headerLinksRight?.map((b) => (
              <li
                className={classNames("Header-navItem", {
                  "is-red": b.is_red,
                })}
                key={b._uid}
              >
                <Link
                  href={
                    b.link.story?.full_slug
                      ? `/${b.link.story.full_slug}`
                      : b.link.url
                  }
                  onMouseEnter={() => {
                    setActiveCat(null);
                  }}
                >
                  <span>{b.text || b.link.story?.name}</span>
                </Link>
              </li>
            ))}
          </ul>

          <div className="Header-buttons">
            <Link
              className="Header-accountButton Header-accountButton--desktop"
              href="/account"
              aria-label={t("account")}
            >
              <i
                className={classNames("Icon", {
                  "Icon--user": !selectionData?.loggedIn,
                  "Icon--userCheck": selectionData?.loggedIn,
                })}
              />
            </Link>

            <button
              className="Header-cartButton"
              aria-label={t("cart")}
              onClick={() => {
                const openCart = new CustomEvent("artilleriet.togglecart");
                window.dispatchEvent(openCart);
                setSearchIsActive(false);
              }}
            >
              <i className="Icon Icon--cart" />

              {bagItemsCount ? <span>{bagItemsCount}</span> : null}
            </button>

            <div className="Header-currencyDropdown">
              <button
                className="Header-currencyButton"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                aria-label="Select currency"
              >
                {!isCheckoutPage && <>{currency} / </>}
                {router.locale}
              </button>
            </div>
          </div>
        </div>
        {dropdownOpen && (
          <div className="Header-currencyOptions">
            {!isCheckoutPage && (
              <div className="Header-currencyOption">
                <button
                  className={classNames({
                    "is-active": currency === "EUR",
                  })}
                  onClick={() => handleCurrencySelect("EUR")}
                >
                  EUR
                </button>
                {" / "}
                <button
                  className={classNames({
                    "is-active": currency === "SEK",
                  })}
                  onClick={() => handleCurrencySelect("SEK")}
                >
                  SEK
                </button>
              </div>
            )}

            <div className="Header-currencyOption">
              <Link
                href={router.asPath}
                locale="en"
                onClick={() => {
                  Cookies.set("NEXT_LOCALE", "en");
                  setSelectionLanguage("en").then((data) => console.log(data));
                }}
              >
                EN
              </Link>
              {" / "}
              <Link
                href={router.asPath}
                locale="sv"
                onClick={() => {
                  Cookies.set("NEXT_LOCALE", "sv");
                  setSelectionLanguage("sv").then((data) => console.log(data));
                }}
              >
                SV
              </Link>
            </div>
          </div>
        )}
      </div>
    </header>
  );

  if (isGiftPage) {
    return (
      <header className="Header">
        <a className="Header-skipToContentLink" href="#main">
          {t("skipToContent")}
        </a>

        <div className="Header-content">
          <div className="Header-navWrapper">
            <ul className="Header-nav Header-nav--left">
              <li className="Header-navItem">
                <button
                  onClick={() => {
                    window.location.href = "/";
                  }}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    gap: "1em",
                  }}
                >
                  <i className="Icon Icon--arrowLeft" />
                  {t("backButton")}
                </button>
              </li>
            </ul>
          </div>
          <div className="Header-center">
            <h3 className="Header-wordmarkWrapper">
              <Link hard={true} href="/">
                <img
                  className="Header-wordmark"
                  src="/images/artilleriet-wordmark.svg"
                  alt="Artilleriet"
                />
              </Link>
            </h3>
          </div>
        </div>
      </header>
    );
  }

  return (
    <>
      {header}

      {/* New custom mega menu links */}
      {headerCustomMenuItems &&
        headerCustomMenuItems.map((b) => {
          const cat = b.link[0].link.story || b.link[0].link;
          const featuredLinks = b.featured_links;

          if (!cat) {
            console.error("no cat found");
            console.log(b);
            return;
          }

          return (
            <Modal
              bodyOpenClassName="is-showingMenu"
              className="Header-menuModal"
              isOpen={menuIsVisible && activeCat === cat}
              key={cat?.id || "asdfsdgsgdfkf"}
              onRequestClose={() => setActiveCat(null)}
              shouldCloseOnOverlayClick={false}
              overlayElement={(props, element) =>
                React.cloneElement(element, {
                  ...props,
                  ref: menuRef,
                  onMouseOver(event) {
                    if (event.target === menuRef.current) {
                      setActiveCat(null);
                    }
                  },
                })
              }
              style={{
                overlay: {
                  backgroundColor: "var(--color-black-opacity)",
                  overflow: "auto",
                  top: "var(--header-height)",
                  zIndex: 105,
                },
              }}
            >
              <div className="Header-menu">
                <div className="Header-menuContent Header-menuContent--rightLinks">
                  {featuredLinks.length && (
                    <div className="Header-menuFeatured">
                      <div className="Header-menuFeaturedLinks">
                        {featuredLinks?.map((b) => (
                          <Link
                            className="Header-menuFeaturedLink"
                            key={b._uid}
                            href={
                              b.link.linktype === "story"
                                ? b.link.cached_url
                                : b.link.url
                            }
                          >
                            <StoryblokImage
                              className="Header-menuFeaturedLinkImage"
                              asset={b.image}
                              sizes="20vw"
                            />
                            <div className="Header-menuFeaturedLinkText">
                              {b.text}
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}

                  {b.navigation_text && (
                    <div
                      className="Header-menuRightLinkText u-richText"
                      dangerouslySetInnerHTML={{
                        __html: b.navigation_text.content,
                      }}
                    />
                  )}
                </div>
              </div>
            </Modal>
          );
        })}

      {headerLinksLeft
        .filter((b) => b.link.story?.children)
        .map((b) => {
          const cat = b.link.story;
          let letterGroups: { [key: string]: StoryWithChildren[] };

          if (cat.full_slug === "brands") {
            const sortedChildren = sortBy(cat.children, getFirstLetter);
            letterGroups = groupBy(sortedChildren, getFirstLetter);
          }

          return (
            <Modal
              bodyOpenClassName="is-showingMenu"
              className="Header-menuModal"
              isOpen={menuIsVisible && activeCat === cat}
              key={cat.id}
              onRequestClose={() => setActiveCat(null)}
              shouldCloseOnOverlayClick={false}
              overlayElement={(props, element) =>
                React.cloneElement(element, {
                  ...props,
                  ref: menuRef,
                  onMouseOver(event) {
                    if (event.target === menuRef.current) {
                      setActiveCat(null);
                    }
                  },
                })
              }
              style={{
                overlay: {
                  backgroundColor: "var(--color-black-opacity)",
                  overflow: "auto",
                  top: "var(--header-height)",
                  zIndex: 105,
                },
              }}
            >
              <div className="Header-menu">
                <div className="Header-menuContent">
                  {cat.full_slug === "brands" ? (
                    <div className="Header-menuHeader">
                      <button
                        className="Header-menuClose"
                        onClick={() => setActiveCat(null)}
                      >
                        <i className="Icon Icon--clear" />
                        <span>{t("menuClose")}</span>
                      </button>
                    </div>
                  ) : null}

                  {cat.full_slug !== "brands" ? (
                    <ul className="Header-menuColumns">
                      {cat.children.map((m, i) => (
                        <li
                          className="Header-menuColItem"
                          key={m.id}
                          style={{ "--index": i } as React.CSSProperties}
                        >
                          <h4 className="Header-menuListItem Header-menuListItem--heading">
                            <Link href={"/" + m.full_slug} prefetch={false}>
                              {m.content.name}
                            </Link>
                          </h4>

                          {!m.children && cat.full_slug !== "brands" && (
                            <div className="Header-menuListItem Header-menuListItem--viewAll">
                              <Link href={"/" + m.full_slug} prefetch={false}>
                                {t("viewAll")}
                              </Link>
                            </div>
                          )}

                          {m.children && (
                            <ul className="Header-menuList">
                              {m.children.map((c) => (
                                <li className="Header-menuListItem" key={c.id}>
                                  <Link
                                    href={"/" + c.full_slug}
                                    prefetch={false}
                                    onClick={() => {
                                      setMenuIsOpen(false);
                                      setActiveCat(null);
                                    }}
                                  >
                                    {c.content.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  ) : null}

                  {cat.full_slug === "brands" ? (
                    <ul className="Header-menuColumns">
                      {Object.entries(letterGroups).map(([letter, cats], i) => {
                        if (letter) {
                          return (
                            <li
                              className="Header-menuColItem Header-menuColItem--brand"
                              key={letter}
                              style={{ "--index": i } as React.CSSProperties}
                            >
                              <h4 className="Header-menuLetterHeading">
                                {letter.toUpperCase()}
                              </h4>

                              <ul className="Header-menuList Header-menuList--brand">
                                {sortBy(cats, [
                                  (o) => o.name.toLowerCase(),
                                ]).map((m) => (
                                  <h4
                                    className="Header-menuListItem"
                                    key={m.id}
                                  >
                                    <Link
                                      href={"/" + m.full_slug}
                                      prefetch={false}
                                      onClick={() => {
                                        setMenuIsOpen(false);
                                        setActiveCat(null);
                                      }}
                                    >
                                      {m.content.name}
                                    </Link>
                                  </h4>
                                ))}
                              </ul>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  ) : null}

                  {featuredLinks || featuredProductsData?.length ? (
                    <div className="Header-menuFeatured">
                      <div className="Header-menuFeaturedLinks">
                        {featuredLinks?.map((b) => (
                          <Link
                            className="Header-menuFeaturedLink"
                            key={b._uid}
                            href={
                              b.link.linktype === "story"
                                ? b.link.cached_url
                                : b.link.url
                            }
                          >
                            <StoryblokImage
                              className="Header-menuFeaturedLinkImage"
                              asset={b.image}
                              sizes="20vw"
                            />
                            <div className="Header-menuFeaturedLinkText">
                              {b.text}
                            </div>
                          </Link>
                        ))}

                        {featuredProductsData?.map((item, i) => (
                          <CentraProductLink
                            className="Header-menuFeaturedLink"
                            key={item.product.product}
                            product={item.product}
                            style={{ "--index": i } as React.CSSProperties}
                            shallow
                            onClick={() => {
                              setActiveCat(null);
                            }}
                          >
                            <CentraProductImage
                              className="Header-menuFeaturedLinkImage"
                              src={item.product.media.portrait?.[0]}
                              sizes="20vw"
                            />
                            <div className="Header-menuFeaturedLinkText">
                              {item.product.name}
                            </div>
                          </CentraProductLink>
                        ))}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </Modal>
          );
        })}

      {/* <Modal
        bodyOpenClassName="is-showingMenu"
        className="Header-menuModal"
        isOpen={searchIsActive}
        onRequestClose={() => setSearchIsActive(false)}
        shouldCloseOnOverlayClick={false}
        overlayElement={(props, element) =>
          React.cloneElement(element, {
            ...props,
            ref: searchMenuRef,
            onClick(event) {
              if (event.target === searchMenuRef.current) {
                setSearchIsActive(false);
              }
            },
          })
        }
        style={{
          overlay: {
            backgroundColor: "var(--color-black-opacity)",
            overflow: "auto",
            top: "var(--header-height)",
            zIndex: 105,
          },
        }}
      >
        <div className="Header-menu Header-menu--search">
          <div className="Header-menuSearch">
            <div
              className="Header-menuSearchLabel"
              // onSubmit={submitSearch}
            >
              <div
                // autoFocus
                // autoComplete="off"
                className="Header-menuSearchInput"
                // name="s"
                // onChange={(event) => setSearchTerm(event.currentTarget.value)}
                // placeholder={t("search.inputLabel")}
                // required
                // value={searchTerm}
              ></div>

              {loadingSearchResults ? (
                <Spinner className="Header-menuSearchSpinner" />
              ) : (
                <button className="Header-menuSearchButton">
                  <i className="Icon Icon--search" />
                  <span>{t("search.inputLabel")}</span>
                </button>
              )}
            </div>

            {categorySearchResults.length ? (
              <>
                <h4 className="Header-menuSearchHeading Header-menuSearchHeading--noPadding">
                  {t("search.catsHeading")}
                </h4>

                <ul className="Header-menuList">
                  {categorySearchResults.map((p) => (
                    <li className="Header-menuListItem" key={p.id}>
                      <Link href={"/" + p.full_slug} prefetch={false}>
                        {p.parent_name ? (
                          <>
                            <span>{p.parent_name}</span>
                            <i className="Icon Icon--arrowRight" />
                          </>
                        ) : null}
                        {p.content.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            ) : null}
          </div>

          <div className="Header-menuSearchBody">
            {productSearchResults.length ? (
              <>
                <h4 className="Header-menuSearchHeading">
                  {t("search.desktopHeading", { n: searchResultsTotal })}
                </h4>

                <div className="Header-menuSearchResults">
                  <SWRConfig
                    value={{
                      revalidateOnMount: false,
                    }}
                  >
                    {productSearchResults.map((p) => (
                      <ProductGridItem
                        className="ProductGrid-item--small"
                        key={p.product}
                        productId={p.product}
                      />
                    ))}
                  </SWRConfig>
                </div>

                {searchResultsTotal > productSearchResults.length ? (
                  <div className="Header-menuSearchFooter">
                    <button
                      className="Header-menuSearchPaginationButton"
                      disabled={loadingSearchResults}
                      onClick={loadMoreSearchResults}
                    >
                      {t("search.loadMore")}
                    </button>
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </Modal> */}

      <Modal
        bodyOpenClassName="is-showingMenu"
        className="MobileMenu-modal"
        isOpen={menuIsOpen}
        onRequestClose={() => setMenuIsOpen(false)}
        style={{
          overlay: {
            backgroundColor: "var(--color-black-opacity)",
            overflow: "auto",
            top: "var(--header-height)",
            zIndex: 105,
          },
        }}
      >
        <MobileMenu />
      </Modal>

      <Script
        id="freshdesk"
        data-cookieconsent="preferences"
        type="text/plain"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
            function initFreshChat() {
            window.fcWidget.init({
            token: "2ce8b274-6f26-4714-a47a-cc3786f78985",
            host: "https://wchat.freshchat.com"
            });
            }
            function initialize(i,t){
            var e;
            i.getElementById(t) ? initFreshChat() :
            ((e=i.createElement("script")).id=t,
            e.async=!0,e.src="https://wchat.freshchat.com/js/widget.js",
            e.onload=initFreshChat,i.head.appendChild(e))
            }
            initialize(document,"freshchat-js-sdk")
        `,
        }}
      />
    </>
  );
}
